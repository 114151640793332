import {
    Button,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Grid,
    GridItem,
    HStack,
    VStack,
    Text,
} from '@chakra-ui/react'
import { useColorMode  } from '@chakra-ui/react'
import { Icon} from "@chakra-ui/icons"
import {useDisclosure} from "@chakra-ui/hooks";
import { useState, useEffect } from 'react';
import {FaTrashAlt} from 'react-icons/fa';
import axios from 'axios';
import CancelCodemagicBuildModal from "./cancel_build_modal";
import config from '../config';

function CodemagicData({userToken, gameIdentity})
{
    const [codemagicData, setCodemagicData] = useState(null);
    const { colorMode, setColorMode } = useColorMode()
    setColorMode('dark');

    const fetchData = () => {
        let url = config.API_ENDPOINT + '/codemagic/';
        axios.get(url,  {headers: {Authorization: "Bearer " + userToken}})
            .then((response) => {
                let builds = response.data["builds"];
                let activeBuilds = [];
                for(let i = 0; i < builds.length; i++) {
                    if(builds[i].status === "building" ||  builds[i].status === "queued") {
                        activeBuilds.push(builds[i]);
                    }
                }
                response.data["builds"] = activeBuilds;
                setCodemagicData(response.data);
            });
    };


    useEffect(() => {
        // Call fetchData immediately on component mount
        fetchData();

        // Set up an interval to periodically fetch data
        const intervalId = setInterval(fetchData, 4000); // 4 seconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [gameIdentity]); // Dependencies array ensures this runs only when gameIdentity changes

    return <DisplayCodemagicData userToken={userToken} codemagicData={codemagicData}/>

}


function DisplayCodemagicData({userToken, codemagicData})
{
    const cancelBuildDisclosure = useDisclosure();

    return (
        <Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
            <VStack  spacing={1}  align='stretch'>
                <HStack width={'100%'}>
                    <SectionTitle />
                </HStack>
                <TableContainer>
                    <Table variant='simple' size='md' border={'0px'}>
                        <Thead>
                            <Tr>
                                <Th width="150px">Game Name</Th>
                                <Th width="200px">Status</Th>
                                <Th width="200px">Branch</Th>
                                <Th width="200px">Start Date(UTC)</Th>
                                <Th width="150px">Cancel</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {codemagicData && codemagicData.builds.map((field, i) => (
                                <Tr>
                                    <CancelCodemagicBuildModal userToken={userToken} disclosure={cancelBuildDisclosure} build_id={field._id}/>
                                    <Td  width="150px">{GetAppNameFromId(codemagicData.apps, field.appId)}</Td>
                                    <Td  width="150px">{field.status}</Td>
                                    <Td  width="150px">{field.branch}</Td>
                                    <Td  width="200px">{field.startedAt}</Td>
                                    <Td  width="50px"><Button onClick={()=>cancelBuildDisclosure.onOpen()} leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color:'gray.300' }} />}   variant='simple'/></Td>

                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </Box>
    );
}

function GetAppNameFromId(appData, appId)
{
    for (let i = 0; i < appData.length; i++) {
        if (appData[i]._id === appId)
            return appData[i].appName;
    }
    return null;
}

function SectionTitle(){
    return (
        <HStack width='100%'>
            <Box flex={1} h='40px' bg='gray.800' paddingLeft={'10px'}>
                <HStack spacing='-5px' padding={'5px'}>
                    <strong>Codemagic Builds</strong>
                </HStack>
            </Box>
        </HStack>
    );
}

function CodemagicBuilds({userToken, selectedGameId}) {
    return (
        <Grid
            height={'1px'}
            width={'100%'}
            templateRows='repeat(4, 1fr)'
            templateColumns='repeat(5, 1fr)'
            gap={2}
            paddingLeft={'12px'}
            paddingRight={'10px'}
        >
            <GridItem colSpan={6} padding={'15px'}>
            </GridItem>
            <GridItem paddingLeft={'20px'} colSpan={6}>
                <CodemagicData userToken={userToken} gameIdentity={selectedGameId}/>
            </GridItem>
        </Grid>
    );
}

export default CodemagicBuilds;
