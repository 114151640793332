import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Button,
    HStack,
    VStack,
} from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';

const DeleteRCKeyModal = ({userToken, packageId, rcKey, rcValueId, disclosure}) => {
    const [isDeleting, setIsDeleting] = React.useState(false);
    function deleteRCValue(userToken, packageId, rcKey, rcValueId)
    {
        setIsDeleting(true);
        let url = config.API_ENDPOINT + '/rc_package/' + packageId + '/' + rcValueId;
        axios.delete(url,
            {headers: {Authorization: "Bearer " + userToken}})
            .then((response) => {
                console.log(response);
                disclosure.onClose();
                setIsDeleting(false);
            })
            .catch((error) => {
                console.error(error);
                setIsDeleting(false);
            });

    }

    return (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>Delete {rcKey}</ModalHeader>
                <ModalCloseButton />
                <ModalBody >
                    <VStack>
                        <HStack>
                            <Button variant="outline" mr={3} onClick={disclosure.onClose}>Cancel</Button>
                            {isDeleting ? (
                                <Spinner />
                            ) : (
                                <Button variant="outline" bgColor={'red.400'} mr={3} onClick={() => deleteRCValue(userToken, packageId, rcKey, rcValueId)}>Proceed</Button>
                            )}
                        </HStack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default DeleteRCKeyModal;
