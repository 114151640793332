import React, {useEffect, useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Input,
    VStack,
    Spinner,
    Checkbox,
    FormLabel, Select, FormControl,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';

const RemoteConfigPackageModal = ({userToken, identifier, title,  isOpen, onClose}) => {
    const [userPackageList, setUserPackageList] = useState([]);
    const [packageToInherit, setPackageToInherit] = useState(null);
    const [deviceList, setDeviceList] = useState([]);
    const [configName, setConfigName] = useState(null);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [showSubmitButton, setShowSubmitButton] = useState(true);

    useEffect(() => {
        let bearerToken = "Bearer " + userToken;
        axios.get(config.API_ENDPOINT + '/rc_package/free_device_list', {headers: {Authorization: bearerToken}})
            .then((response) => {
                setDeviceList(response.data);
                //console.log(deviceList);
            });
    }, [isOpen]);

    useEffect(() => {
        let bearerToken = "Bearer " + userToken;
        let url = config.API_ENDPOINT + '/rc_package/list/' + identifier;
        axios.get(url, {headers: {Authorization: bearerToken}})
            .then((response) => {
                setUserPackageList(response.data);
                //console.log(deviceList);
            });
    }, [isOpen]);

    const resetForm = () => {
        setPackageToInherit('');
        setConfigName('');
    };

    const handleSubmit = (event) => {
        console.log("handleSubmitCalled");
        setShowSubmitButton(false);
        if(packageToInherit !== null && packageToInherit !== undefined && packageToInherit.length > 0) {
            let bearerToken = "Bearer " + userToken;
            //console.log(packageToInherit)
            axios.get(config.API_ENDPOINT + '/rc_package/info/' + packageToInherit, {headers: {Authorization: bearerToken}})
                .then((response) => {
                    sendPostRequest(response.data.Values);
                    //console.log(response.data.Values);
                });
        }
        else
        {
            sendPostRequest([]);
        }
    };

    const sendPostRequest = (packageValues) => {
        let packageData = {
            Name: configName,
            AssignedDeviceIds: selectedDevices,
            Values: packageValues,
            Identifier: identifier,
        }

        let url = config.API_ENDPOINT + '/game/' + identifier + '/rc_package';
        axios.post(url, packageData,
            {headers: {Authorization: "Bearer " + userToken}})
            .then((response) => {
                console.log(response);
                resetForm();
                setShowSubmitButton(true);
                onClose();
            });
    }

    const handleCheckboxChange = (deviceId) => {
        //console.log("HandleCheckboxChange");

        if(selectedDevices.includes(deviceId)) {
            const index = selectedDevices.indexOf(deviceId);
            let temp = [...selectedDevices];
            if (index > -1) { // only splice array when item is found
                temp.splice(index, 1); // 2nd parameter means remove one item only
            }
            setSelectedDevices([...temp]);
        }
        else
        {
            setSelectedDevices([...selectedDevices, deviceId]);
        }
        //console.log(selectedDevices);
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody >
                        <Input placeholder='Config Name'  value={configName} onChange={(event) => setConfigName(event.target.value)} />
                        <FormControl flex="1" mr={2} >
                            <FormLabel paddingTop={'10px'}>Inherit Config</FormLabel>
                            <Select
                                placeholder="Select Config Package"
                                value={packageToInherit}
                                onChange={(e) => setPackageToInherit(e.target.value)}
                            >
                                {userPackageList.map((packg, index) => (
                                    <option key={index} value={packg._id}>{packg.Name}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <VStack  align={'left'} padding={'10px'} width={'550px'} paddingTop={'20px'}>
                            <Text fontSize='lg'>Device List</Text>
                            <VStack  align={'left'} width={'550px'}>
                                {deviceList.map((device, index) => (
                                    <Checkbox  key={index} value={device._id} onChange={(event) => handleCheckboxChange(device._id)}>{device.device_name}</Checkbox>
                                ))}
                            </VStack>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        {showSubmitButton &&
                            <Button variant="outline" mr={3} onClick={()=>handleSubmit()}>Submit</Button>
                        }
                        {!showSubmitButton &&
                            <Spinner/>
                        }
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default RemoteConfigPackageModal;
