import React, {useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    HStack,
    VStack,
    Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';


const DeleteRemoteConfigPackageModal = ({userToken, packageId, title, disclosure}) => {
    const sendPostRequest = () => {
        let url = config.API_ENDPOINT + '/rc_package/' + packageId;
        axios.delete(url, {headers: {Authorization: "Bearer " + userToken}})
            .then((response) => {
                console.log(response);
                disclosure.onClose();
            });
    }


    return (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                    <ModalBody >
                        <VStack>
                            <HStack>
                                <Button variant="outline" mr={3} onClick={disclosure.onClose}>Cancel</Button>
                                <Button variant="outline" bgColor={'red.400'}  mr={3} onClick={()=>sendPostRequest()}>Proceed</Button>
                            </HStack>
                        </VStack>
                    </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default DeleteRemoteConfigPackageModal;
