import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Select,
    Textarea,
    Stack,
    FormControl,
    FormLabel,
    Box,
    HStack
} from '@chakra-ui/react';
import axios from "axios";
import { FilterCondition, populateFilters, formatValue, formatFilters } from "../utility";
import { Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Countries } from "./enums";
import config from '../config';

// The initial state for a new filter
const initialFilter = { Filter: '', Condition: FilterCondition.NoOp, Values: '' };

const EditRCVariableModal = ({ userToken, disclosure, rcConfigId, variableData }) => {
    const [rcValueData, setrRCValueData] = useState(variableData);
    const [variableKey, setVariableKey] = useState(variableData.Key || '');
    const [description, setDescription] = useState(variableData.Description || '');
    const [variableType, setVariableType] = useState(variableData.Type || '');
    const [variableValue, setVariableValue] = useState(variableData.Value || '');
    const [filters, setFilters] = useState([populateFilters(initialFilter, variableData.FilterOperations)]);

    useEffect(() => {
        const populatedFilters = populateFilters(initialFilter, variableData.FilterOperations);
        setFilters(populatedFilters);
    }, [variableData.FilterOperations]);

    // Function to handle adding a new filter
    const handleAddFilter = () => {
        setFilters([...filters, { ...initialFilter }]);
    };

    // Function to handle removing an existing filter
    const handleRemoveFilter = (index) => {
        setFilters(filters.filter((_, i) => i !== index));
    };

    // Function to handle changes to filter fields
    const handleFilterChange = (index, field, value) => {
        const newFilters = filters.map((filter, i) =>
            i === index ? { ...filter, [field]: value } : filter
        );
        setFilters(newFilters);
    };

    // Function to handle the form update/submit
    const handleUpdate = async (event) => {
        event.preventDefault();
        // Logic to update the variable, typically involves sending updated data to a server
        console.log('Updated variable with filters:', filters);
        disclosure.onClose();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Logic to create the variable, typically involves sending the data to a server
        console.log('Created variable with filters:', filters);

        let payload = {
            _id: rcValueData._id,
            Key: variableKey,
            Description: description,
            Type: variableType,
            Value: formatValue(variableValue, variableType),
            FilterOperations: formatFilters(filters),
        };

        console.log(payload);

        let url = config.API_ENDPOINT + '/rc_package/' + rcConfigId + '/' + rcValueData._id;
        axios.put(url, payload, { headers: { Authorization: "Bearer " + userToken } })
            .then((response) => {
                console.log(response.data);
                console.log(response.status);
                console.log(response.statusText);
                console.log(response.headers);
                console.log(response.config);
            });

        disclosure.onClose();
    }

    return (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent maxW="950px">
                <ModalHeader>Edit Variable</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleUpdate}>
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    value={variableKey}
                                    onChange={(e) => setVariableKey(e.target.value)}
                                    placeholder="Variable Key"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Description"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Type</FormLabel>
                                <Select
                                    placeholder="Select Type"
                                    value={variableType}
                                    onChange={(e) => setVariableType(e.target.value)}
                                >
                                    <option value="Integer">Integer</option>
                                    <option value="Float">Float</option>
                                    <option value="Boolean">Boolean</option>
                                    <option value="String">String</option>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Value</FormLabel>
                                {/* Conditional rendering for the Value input based on selected Type */}
                                {variableType === "Boolean" ? (
                                    <Select
                                        value={variableValue}
                                        onChange={(e) => setVariableValue(e.target.value)}
                                        placeholder="Select Value"
                                    >
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </Select>
                                ) : (
                                    <Textarea
                                        value={variableValue}
                                        onChange={(e) => setVariableValue(e.target.value)}
                                        placeholder="Value"
                                    />
                                )}
                            </FormControl>
                            {filters.map((filter, index) => (
                                <HStack key={index} spacing={4}>
                                    <Select
                                        placeholder="Select Filter Type"
                                        value={filter.Filter}
                                        onChange={(e) => handleFilterChange(index, 'Filter', e.target.value)}
                                    >
                                        <option value='Country'>Country</option>
                                        <option value='Version'>Version</option>
                                        <option value='Platform'>Platform</option>
                                    </Select>
                                    <Select
                                        placeholder="Select Condition"
                                        value={filter.Condition}
                                        onChange={(e) => handleFilterChange(index, 'Condition', e.target.value)}
                                    >
                                        {Object.entries(FilterCondition).map(([key, condition]) => (
                                            <option key={key} value={condition}>{condition}</option>
                                        ))}
                                    </Select>
                                    <Input
                                        value={filter.Values}
                                        onChange={(e) => handleFilterChange(index, 'Values', e.target.value)}
                                        placeholder="Filter Value"
                                    />
                                    {index === filters.length - 1 ? (
                                        <Button width={'350px'} colorScheme="blue" onClick={handleAddFilter}>Add Filter</Button>
                                    ) : (
                                        <Button width={'350px'} colorScheme="red" onClick={() => handleRemoveFilter(index)}>Remove Filter</Button>
                                    )}
                                </HStack>
                            ))}
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="outline" mr={3} onClick={disclosure.onClose}>Cancel</Button>
                        <Button colorScheme="blue" type="submit" onClick={handleSubmit}>Update</Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default EditRCVariableModal;