import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormControl,
    FormLabel,
    Checkbox,
    Stack,
    Box,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';

const EditRemoteConfigModal = ({ userToken, disclosure, packageData}) => {
    const [packageName, setPackageName] = useState(packageData.Name);
    const [selectedDevices, setSelectedDevices] = useState(packageData.AssignedDeviceIds || []);
    const [deviceList, setDeviceList] = useState([]);


    useEffect(() => {
        let bearerToken = "Bearer " + userToken;
        let url = config.API_ENDPOINT + '/rc_package/available_device_list/' + packageData._id;
        axios.get(url, {headers: {Authorization: bearerToken}})
            .then((response) => {
                setDeviceList(response.data);
                //console.log(selectedDevices);
            });
    }, [disclosure.isOpen]);

    const handlePackageNameChange = (e) => {
        setPackageName(e.target.value);
    };

    const handleDeviceChange = (deviceId) => {
        setSelectedDevices((prevDevices) =>
            prevDevices.includes(deviceId)
                ? prevDevices.filter((id) => id !== deviceId)
                : [...prevDevices, deviceId]
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare the data to be sent
        const updatedConfig = {
            ...packageData, // spread the existing config
            Name: packageName,
            AssignedDeviceIds: selectedDevices,
        };

        try {
            const response = await axios.put(
                `https://logger.alictusapi.com:54280/rc_package/` + packageData._id,
                updatedConfig,
                {
                    headers: {
                        "Authorization": 'Bearer ' + userToken,
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log(response.data);
            disclosure.onClose(); // Close the modal
        } catch (error) {
            console.error(error);
        }
        resetForm();
    };

    const resetForm = () => {
        setSelectedDevices([]);
        setDeviceList([])
    }

    return (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Remote Config Package</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <FormControl id="package-name" isRequired>
                            <FormLabel>Package Name</FormLabel>
                            <Input
                                placeholder="Enter the package name"
                                value={packageName}
                                onChange={handlePackageNameChange}
                            />
                        </FormControl>

                        <Box mt={4}>
                            <FormLabel>Devices</FormLabel>
                            <Stack spacing={2}>
                                {deviceList.map((device) => (
                                    <Checkbox
                                        key={device._id}
                                        isChecked={selectedDevices.includes(device._id)}
                                        onChange={() => handleDeviceChange(device._id)}
                                    >
                                        {device.device_name}
                                    </Checkbox>
                                ))}
                            </Stack>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} type="submit">
                            Save
                        </Button>
                        <Button variant="ghost" onClick={disclosure.onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default EditRemoteConfigModal;