// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { FcGoogle } from 'react-icons/fc'
import {Button, Center, Text} from '@chakra-ui/react'
import {useEffect, useState} from "react";
import NavBar from "./navbar";


const firebaseConfig = {
    apiKey: "AIzaSyAlxot5lzKA_u5y6rmAuHB4r8tbyKadKmg",
    authDomain: "borderdefenseio.firebaseapp.com",
    projectId: "borderdefenseio",
    storageBucket: "borderdefenseio.appspot.com",
    messagingSenderId: "104420919203",
    appId: "1:104420919203:web:b9fa62fdd44f23ce2fbc16",
    measurementId: "G-YEPMBG8KXW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);


export default function LoginView() {

    const [userToken, setUserToken] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState(null);
    useEffect(() => {
        document.title = 'Alictus Services Dashboard';
    }, []);

    const refreshToken = async (user) => {
        try {
            const newToken = await user.getIdToken(true);
            setUserToken(newToken);
            setAvatarUrl(user.photoURL);

            // Schedule next token refresh
            setTimeout(() => refreshToken(user), 55 * 60 * 1000); // Refresh every 55 minutes
        } catch (error) {
            console.error("Error refreshing token:", error);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in
                refreshToken(user);
            } else {
                // User is signed out
                setUserToken(null);
                setAvatarUrl(null);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);


    function onSignIn() {
        //console.log("Inside onSignIn")
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                //const token =  result.user.getIdToken();
                setAvatarUrl(result.user.photoURL);
                const token = auth.currentUser.getIdToken().then((token) => {
                    const user = result.user;
                    refreshToken(user);
                    //setUserToken(token);
                    //console.log(token);
                    //console.log(user);
                });
            }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }

    function showLogin() {
     return <Center p={8}>
         <Button w={'full'} maxW={'md'} variant={'outline'} leftIcon={<FcGoogle/>} onClick={() => onSignIn()}>
             <Center>
                 <Text>Sign in with Google</Text>
             </Center>
         </Button>
     </Center>
    }
//<LogView userToken={userToken}/>
    function showSite() {
        if (userToken !== null && userToken !== undefined) {
            return <NavBar {...{userToken, setUserToken, avatarUrl}}/>
        }
        return showLogin();
    }

    return showSite();
}

