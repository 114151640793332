import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Select,
    Textarea,
    Stack,
    FormControl,
    FormLabel,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';

function formatValue(value, Type){
    if(Type === "Float") {
        return parseFloat(value);
    }
    else if(Type === "Integer") {
        return parseInt(value);
    }
    else if(Type === "Boolean") {
        return value === "true";
    }
    else {
        return value;
    }
}

const AddRCVariableModal = ({userToken, disclosure, packageData}) => {
    const [variableKey, setVariableKey] = useState('');
    const [isGameVariable, setIsGameVariable] = useState(false);
    const [description, setDescription] = useState('');
    const [variableType, setVariableType] = useState('');
    const [variableValue, setVariableValue] = useState('');

    const resetForm = () => {
        setVariableKey('');
        setDescription('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here, you would typically handle the form submission, for instance sending the data to a server.
        resetForm();
        disclosure.onClose();
    };


    return (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create Variable</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Stack spacing={4}>
                            <FormControl flex="1" mr={2} >
                                <FormLabel>Select Variable Type</FormLabel>
                                <Select
                                    placeholder="Select Variable Type"
                                    value={isGameVariable}
                                    onChange={(e) => setIsGameVariable(e.target.value)}
                                >
                                    <option value="Integer">Remote Config</option>
                                    <option value="Float">Game Variable</option>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input value={variableKey} onChange={(event) => setVariableKey(event.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Input value={description} onChange={(event) => setDescription(event.target.value)} />
                            </FormControl>
                            <FormControl flex="1" mr={2} >
                                <FormLabel>Type</FormLabel>
                                <Select
                                    placeholder="Select Type"
                                    value={variableType}
                                    onChange={(e) => setVariableType(e.target.value)}
                                >
                                    <option value="Integer">Integer</option>
                                    <option value="Float">Float</option>
                                    <option value="Boolean">Boolean</option>
                                    <option value="String">String</option>
                                </Select>
                            </FormControl>
                            {variableType === "Boolean" ? (
                                    <FormControl>
                                        <FormLabel>Value</FormLabel>
                                        <Select
                                            value={variableValue}
                                            onChange={(event) => setVariableValue(event.target.value)}
                                            placeholder="Select Boolean Value"
                                        >
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </Select>
                                    </FormControl>
                                ) :
                                variableType === "Integer" || variableType === "Float" || variableType === "Byte" ? (
                                        <NumberInput>
                                            <NumberInputField  value={variableValue}  onChange={(event) => setVariableValue(event.target.value)}/>
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    ) :variableType === "String" && (
                                    <FormControl>
                                        <FormLabel>Value</FormLabel>
                                        <Textarea placeholder='Variable Value' value={variableValue} onChange={(event) => setVariableValue(event.target.value)} />
                                    </FormControl>)
                            }
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="outline" mr={3} onClick={disclosure.onClose}>Cancel</Button>
                        <Button colorScheme="blue" type="submit"
                                onClick={() =>
                                {
                                    let url = config.API_ENDPOINT + '/rc_package/' + packageData._id + '/rc_value';
                                    axios.post(url, {
                                        Key: variableKey,
                                        Description: description,
                                        Value: formatValue(variableValue,variableType),
                                        Type: variableType,
                                        FilterOperations: [],
                                        IsActive: true,
                                    }, {headers: {Authorization: "Bearer " + userToken}})
                                    .then((response) => {
                                        console.log(response.data);
                                        console.log(response.status);
                                        console.log(response.statusText);
                                        console.log(response.headers);
                                        console.log(response.config);
                                    });
                                }}>
                            Create
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default AddRCVariableModal;
