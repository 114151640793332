import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Stack,
    HStack,
    AccordionIcon,
    AccordionButton,
    Box,
    AccordionItem,
    Accordion,
    AccordionPanel,
    InputLeftAddon,
    InputGroup,
    VStack,
    InputRightAddon,
    ModalCloseButton,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper, NumberDecrementStepper, Textarea,
} from '@chakra-ui/react';

import axios from 'axios';
import { stringToArray } from '../utility';
import { RiGridFill } from "react-icons/ri";
import { LuTestTube2 } from "react-icons/lu";
import { FaTrashAlt } from 'react-icons/fa';
import config from '../config';
import { FilterCondition, FilterConditionToByte } from '../utility';
import {Countries, CountryFilterCondition, FilterType} from "../remote_config/enums";
import { Select as ChakraReactSelect } from 'chakra-react-select';

// The initial state for a new filter
const countryList = Countries.map((country) => { return { value: country.code, label: country.name }; });

const ABTestModal = ({ userToken, gameIdentity, title,  isOpen, onClose, incomingData=null}) => {
    const [experimentName, setExperimentName] = useState('');
    const [experimentDescription, setExperimentDescription] = useState('');
    const [platform, setPlatform] = useState('');
    const [rcNames, setRcNames] = useState([]);
    const [variableKey, setVariableKey] = useState('');
    const [version, setVersion] = useState('');
    const [versionFilter, setVersionFilter] = useState('');
    const [buildNumber, setBuildNumber] = useState(0);
    const [buildNumberFilter, setBuildNumberFilter] = useState('');
    const [country, setCountry] = useState([]);
    const [countryFilter, setCountryFilter] = useState('');
    const [dailyUserLimit, setDailyUserLimit] = useState(0);
    const [totalUserCount, setTotalUserCount] = useState(0);
    const [userTargeting, setUserTargeting] = useState('All');
    const [activationEvent, setActivationEvent] = useState('');
    const [activationEventList, setActivationEventList] = useState([]);
    const [responseData, setResponseData] = useState(null);
    const [variants, setVariants] = useState([
        { Name: 'Baseline Variant', RcValues: [] },
        { Name: 'Variant', RcValues: [] },
    ]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newEventName, setNewEventName] = useState('');



    const handleCreateEvent = () => {
        // Send the newEventName to the backend
        // After successful submission, close the modal and reset the input field
        let url = config.API_ENDPOINT + '/ab_test/' + gameIdentity + '/activation_event';
        axios.post(url, {"Name": newEventName},{ headers: { Authorization: "Bearer " + userToken } })
            .then((response) => {

            });
        getActivationEventList();
        setIsModalOpen(false);
        setNewEventName('');
    };

    const getActivationEventList = async () =>
    {
        let url = config.API_ENDPOINT + '/ab_test/' + gameIdentity + '/activation_events';
        axios.get(url, { headers: { Authorization: "Bearer " + userToken } })
            .then((response) => {
                setActivationEventList(response.data);
            });
    }

    const resetState = () => {
        setExperimentName('');
        setExperimentDescription('');
        setPlatform('');
        setVariableKey('');
        setVersion('');
        setVersionFilter('');
        setBuildNumber(0);
        setBuildNumberFilter('');
        setCountry([]);
        setCountryFilter('');
        setTotalUserCount(0);
        setDailyUserLimit(0);
        setUserTargeting('All');
        setActivationEvent('');
        setVariants([
            { Name: 'Baseline Variant', RcValues: [] },
            { Name: 'Variant', RcValues: [] },
        ]);
    };

    useEffect(() => {
        if (!isOpen) {
            console.log("Resetting state");
            resetState();
        }
        else {
            if(incomingData != null) {
                console.log(incomingData);
                setExperimentName(incomingData.Name);
                setExperimentDescription(incomingData.Description);
                if(incomingData.Platform === "ios") {
                    setPlatform("iOS");
                }
                else
                {
                    setPlatform("Android");
                }

                if(incomingData.ActivationEventName !== "") {
                    setActivationEvent(incomingData.ActivationEventName);
                }

                setDailyUserLimit(incomingData.DailyUserLimit);
                setTotalUserCount(incomingData.TotalMaximumUserCount);
                setUserTargeting(incomingData.UserTargeting);
                setBuildNumber(incomingData.BuildNumber);
                setBuildNumberFilter(incomingData.BuildNumberFilter);
                setVersion(incomingData.Version);
                setVersionFilter(incomingData.VersionFilter);
                setCountry(incomingData.Country);
                setCountryFilter(incomingData.CountryFilter);
                setVariants(incomingData.Variations);
            }

            // Fetch the RC names from the database
            const fetchRcNames = async () => {
                try {
                    const response = await axios.get(config.API_ENDPOINT + "/rc_package/main/" + gameIdentity, {
                        headers: { Authorization: `Bearer ${userToken}` }
                    });
                    if (response.data) {
                        console.log(response.data);
                        setResponseData(response.data);
                        let unique_keys = [...new Set(response.data.Values.map(item => item.Key))];
                        //convert unique keys to this format
                        //  {name: "rc_Variable_1", value: "feature_flag_1"},
                        let rcValues = [];
                        unique_keys.map((item) => {
                            rcValues.push({Key: item});
                        });
                        console.log(rcValues);
                        setRcNames(rcValues);
                        //setRcNames(unique_keys);
                    }
                } catch (error) {
                    console.error('Error fetching RC names:', error);
                }
            };
            fetchRcNames();
            getActivationEventList();
        }
    }, [isOpen]);

    const addToVariants = () => {
        let variant = { Name: 'Variant', RcValues: [...variants[0].RcValues] };
        // Iterate variants and return if variant with the same name exists
        let variantExists = variants.some((v) => v.Name === variant.Name);
        if(variantExists) {
            return;
        }
        setVariants([...variants, variant]);
    };

    const deleteVariant = (variantName) => {
        console.log("Delete Variant called with" + variantName);
        // Iterate variants and return if variant with the same name exists
        let arr = variants.filter((item) => item.Name !== variantName);
        setVariants(arr);
    };

    const addVariableToVariants = (variableName) => {
        console.log(variableName);
        const variable = rcNames.find(varObj => varObj.Key === variableName);
        console.log(variable);
        if (variable) {
            const updatedVariants = variants.map((variant) => {
                if (!variant.RcValues.some(varObj => varObj.Key === variable.Key)) {
                    return {
                        ...variant,
                        RcValues: [...variant.RcValues, variable],
                    };
                }
                return variant;
            });
            setVariants(updatedVariants);
        }
    };

    const deleteVariableFromVariants = (variableName) => {
        console.log("deleteVariableFromVariants called");
        const updatedVariants = variants.map((variant) => {
            console.log("Delete Variable: " + variableName);
            console.log(variant)
            const updatedVariables = variant.RcValues.filter((varObj) => varObj.Key !== variableName);
            return {
                ...variant,
                RcValues: updatedVariables,
            };
        });
        console.log(updatedVariants);

        setVariants(updatedVariants);
    };

    const getKeyType = (key) => {
        for (let i = 0; i < responseData.Values.length; i++) {
            if (responseData.Values[i].Key === key) {
                console.log("Type of " + key + " is " + responseData.Values[i].Type);
                return responseData.Values[i].Type;
            }
        }
    }

    const convertValueToType = (value, type) => {
        if(type === "Float") {
            console.log("Converting " + value + " to float");
           if(!value.toString().includes('.'))
           {
                
           }
            return parseFloat(value);
        }
        else if(type === "Integer") {
            console.log("Converting " + value + " to integer");
            return parseInt(value);
        }
        else if(type === "Boolean") {
            console.log("Converting " + value + " to boolean");
            return value.toLowerCase() === "true";
        }
        else if(type === "String") {
            console.log("Converting " + value + " to string");
            return value;
        }
    }

    const formatAbTestVariations = (variants) => {
        let formattedVariants = [];

        for (let i = 0; i < variants.length; i++) {
            let variant = {};

            variant.Name = variants[i].Name;
            variant.Id = variants[i].Id;

            let rcValues = variants[i].RcValues.map((variable) => {
                let valueType = getKeyType(variable.Key);

                return {
                    Key: variable.Key,
                    Value: convertValueToType(variable.Value, valueType),
                    FilterConditions:  []
                };
            });

            variant.RcValues = rcValues;

            let variantStep = 100.0 / variants.length;
            variant.DistributionIntervalStart = variantStep * i;
            variant.DistributionIntervalEnd = variantStep * i + variantStep;
            formattedVariants.push(variant);
        }

        return formattedVariants;

    }


    const createAbTestPayload = () => {

        let filterOperations = [];
        console.log(filterOperations);

        if(country.length > 0) {
            filterOperations.push({
                Filter: 1, //FilterType.Country
                Condition: FilterConditionToByte(countryFilter),
                Values: country.map((country) => country.value)
            });
        }

        if(version !== "") {
            filterOperations.push({
                Filter: 2, //FilterType.Version
                Condition: FilterConditionToByte(versionFilter),
                Values: stringToArray(version)
            });
        }

        if(userTargeting !== "All") {
            filterOperations.push({
                Filter: 7, //FilterType.NewUser
                Condition: 1, //FilterCondition.Equal
                Values: [userTargeting]
            });
        }

        if(buildNumber !== 0) {
            filterOperations.push({
                Filter: 6, //FilterType.BuildNumber
                Condition: FilterConditionToByte(buildNumberFilter),
                Values: [buildNumber]
            });
        }

        let payload = {
            Name: experimentName,
            Description: experimentDescription,
            Variations: formatAbTestVariations(variants),
            ActivationEventName: activationEvent,
            FilterOperations: filterOperations,
            Platform: platform.toLowerCase(),
            UserTargeting: userTargeting,
            BuildNumber: buildNumber,
            BuildNumberFilter: buildNumberFilter,
            Version: version,
            VersionFilter: versionFilter,
            Country: country,
            CountryFilter: countryFilter,
            DailyUserLimit: dailyUserLimit,
            TotalMaximumUserCount: totalUserCount
        };

        return payload;
    }

    const handleUpdateExperiment = (event) => {
        event.preventDefault();

        let payload = createAbTestPayload();

        console.log(payload);

        let url = config.API_ENDPOINT + '/ab_test/' + gameIdentity + '/' + incomingData._id;
        axios.put(url, payload, { headers: { Authorization: "Bearer " + userToken } })
            .then((response) => {

            });
        onClose();
    }

    const handleCreateExperiment = (event) => {
        event.preventDefault();

        let payload = createAbTestPayload();
        console.log(payload);

        let url = config.API_ENDPOINT + '/ab_test/' + gameIdentity
        axios.post(url, payload, { headers: { Authorization: "Bearer " + userToken } })
            .then((response) => {

            });
        onClose();
    }

    const handleCountrySelection = (event) => {
        console.log(event)
        setCountry(event);
    }

    const handleCountrySelectionFilter = (value) => {
        console.log(value)
        setCountryFilter(value);
    }

    const handleVersionSelection = (event) => {
        console.log(event);
        setVersion(event);
    }

    const handleVersionFilterSelection = (event) => {
        console.log(event);
        setVersionFilter(event);
    }

    const handleBuildNumberFilterSelection = (event) => {
        console.log(event);
        setBuildNumberFilter(event);
    }

    const handleBuildNumberSelection = (event) => {
        console.log(event);
        setBuildNumber(parseInt(event, 10));
    }

    const setVariableValue = (variant, variable, value) => {
        let newVariants = variants.map((v) => {
            if (v.Name === variant.Name) {
                return {
                    ...v,
                    RcValues: v.RcValues.map((rcValue) => {
                        if (rcValue.Key === variable.Key) {
                            return {
                                ...rcValue,
                                Value: value,
                            };
                        }
                        return rcValue;
                    }),
                };
            }
            return v;
        });
        setVariants(newVariants);
    }



    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW="950px" border={'1px'} borderColor={'gray.700'}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    <Accordion border={'1px'} borderColor={'gray.700'}>
                        <AccordionItem>
                            <h2>
                                <AccordionButton backgroundColor={'gray.600'}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        Basics
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                    <FormControl>
                        <FormLabel>A/B Name</FormLabel>
                        <Input
                            value={experimentName}
                            onChange={(e) => setExperimentName(e.target.value)}
                        />
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Experiment Description</FormLabel>
                        <Input
                            value={experimentDescription}
                            onChange={(e) => setExperimentDescription(e.target.value)}
                        />
                    </FormControl>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                    <h2>
                        <AccordionButton backgroundColor={'gray.600'}>
                            <Box as="span" flex='1' textAlign='left'>
                                Targeting
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}  zIndex={9999}>
                    <FormControl mt={4}>
                        <FormLabel>Select Platform</FormLabel>
                        <Select
                            placeholder="Select platform"
                            value={platform}
                            onChange={(e) => setPlatform(e.target.value)}
                        >
                            <option value="iOS">iOS</option>
                            <option value="Android">Android</option>
                        </Select>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Select User Targeting</FormLabel>
                        <Select
                            value={userTargeting}
                            onChange={(e) => setUserTargeting(e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="OnlyNew">Only New</option>
                            <option value="OnlyExisting">Only Existing</option>
                        </Select>
                    </FormControl>
                        <FormControl mt={4} paddingTop={'5px'}>
                            <FormLabel>Select Country</FormLabel>
                    <HStack spacing={4} paddingTop={'10px'}>
                        <Select
                            placeholder="Select Condition"
                            value={countryFilter}
                            width={'200px'}
                            onChange={(e) => handleCountrySelectionFilter(e.target.value)}
                        >
                            {Object.entries(CountryFilterCondition).map(([key, condition]) => (
                                <option key={key} value={condition}>{condition}</option>
                            ))}
                        </Select>
                        <Box flex={1}>
                        <ChakraReactSelect options={countryList} value={country} isMulti onChange={(e) => handleCountrySelection(e)}
                                           chakraStyles={{
                            dropdownIndicator: (provided) => ({
                                ...provided,
                                position: 'relative',
                                zIndex: 99999999999, // Adjust this value as needed
                            }),
                            menu: (provided) => ({
                                ...provided,
                                position: 'relative',
                                zIndex: 99999999999, // Adjust this value as needed
                            }),
                        }}
                        />
                        </Box>
                    </HStack>
                        </FormControl>
                        <FormControl mt={4} paddingTop={'5px'}>
                            <FormLabel>Select Version</FormLabel>
                            <HStack spacing={4} paddingTop={'10px'}>
                            <Select
                                placeholder="Select Condition"
                                value={versionFilter}
                                width={'200px'}
                                onChange={(e) => handleVersionFilterSelection(e.target.value)}
                            >
                                {Object.entries(FilterCondition).map(([key, condition]) => (
                                    <option key={key} value={condition}>{condition}</option>
                                ))}
                            </Select>
                                <Box flex={1}>
                            <Input
                                value={version}
                                onChange={(e) => handleVersionSelection(e.target.value)}
                                placeholder="Filter Value"
                            />
                                </Box>
                            </HStack>
                        </FormControl>

                        <FormControl mt={4} paddingTop={'5px'}>
                            <FormLabel>Select Build Number</FormLabel>
                            <HStack spacing={4} paddingTop={'10px'}>
                                <Select
                                    placeholder="Select Condition"
                                    value={buildNumberFilter}
                                    width={'200px'}
                                    onChange={(e) => handleBuildNumberFilterSelection(e.target.value)}
                                >
                                    {Object.entries(FilterCondition).map(([key, condition]) => (
                                        <option key={key} value={condition}>{condition}</option>
                                    ))}
                                </Select>
                                <Box flex={1}>
                                    <NumberInput defaultValue={buildNumber} onChange={(e) => handleBuildNumberSelection(e)}>
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </Box>
                            </HStack>
                        </FormControl>

                        {/* Filters Section */}
                        <FormControl mt={4} paddingTop={'5px'}>
                            <FormLabel>Activation Events</FormLabel>
                            <HStack>
                                <Select
                                    placeholder="Select event"
                                    value={activationEvent}
                                    onChange={(e) => setActivationEvent(e.target.value)}
                                >
                                    {activationEventList.map((event) => (
                                        <option key={event.Name} value={event.Name}>{event.Name}</option>
                                    ))}
                                </Select>
                                <Button width={'250px'} colorScheme="blue" onClick={() => setIsModalOpen(true)}>Create Activation Event</Button>
                            </HStack>
                        </FormControl>
                    </AccordionPanel>
                    </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton backgroundColor={'gray.600'}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        Distribution
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <FormControl mt={4} paddingTop={'5px'}>
                                    <FormLabel>Daily Maximum User Count</FormLabel>
                                    <NumberInput value={dailyUserLimit} onChange={(e) => setDailyUserLimit(parseInt(e, 10))}>
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </FormControl>

                                <FormControl mt={4} paddingTop={'5px'}>
                                    <FormLabel>Total Maximum User Count</FormLabel>
                                    <NumberInput value={totalUserCount} onChange={(e) => setTotalUserCount(parseInt(e, 10))}>
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </FormControl>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton backgroundColor={'gray.600'}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        Variants
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                    <FormControl mt={4} border={'0px'}>
                        {variants.map((variant, index) => (

                            <Stack key={index} paddingBottom={'50px'}>
                                <HStack>
                                    <HStack justifyContent="center" alignItems="center" width={'50px'}>
                                        <RiGridFill style={{color: "gray"}}/>
                                    </HStack>

                                    <Input border={'0px'}
                                           fontStyle={'bold'}
                                           value={variant.Name}
                                           onChange={(e) => {
                                               const newVariants = [...variants];
                                               newVariants[index].Name = e.target.value;
                                               setVariants(newVariants);
                                           }}
                                    />
                                    <Button rightIcon={<FaTrashAlt />} backgroundColor="transparent"  _hover={{ bg: 'transparent' }}
                                            onClick={() => deleteVariant(variant.Name)}>
                                    </Button>
                                </HStack>
                                {//if index is 0, then it is the baseline variant
                                    index === 0 ? (
                                        <Select
                                            value={variableKey}
                                            onChange={(e) => {
                                                setVariableKey(e.target.value);
                                                addVariableToVariants(e.target.value);
                                            }}
                                        >
                                            <option value="">Select a variable</option>
                                            {rcNames.map((rcName) => (
                                                <option key={rcName.Key} value={rcName.Key}>
                                                    {rcName.Key}
                                                </option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <></>
                                    )}

                                <VStack direction="row" mt={2} paddingTop={'15px'}>
                                    {variant.RcValues.map((variable) => (
                                        <InputGroup>
                                            <InputLeftAddon minWidth={'200px'}>
                                                {variable.Key}
                                            </InputLeftAddon>
                                            <Textarea resize='None' size='sm' value={variable.Value} onChange={(e) => setVariableValue(variant, variable, e.target.value)} />
                                            <InputRightAddon><Button
                                                rightIcon={<FaTrashAlt />}
                                                backgroundColor="transparent"
                                                _hover={{ bg: 'transparent' }}
                                                onClick={() => deleteVariableFromVariants(variable.Key)}
                                            >
                                            </Button>
                                            </InputRightAddon>
                                        </InputGroup>
                                    ))}
                                </VStack>
                                {(index !== variants.length - 1) ? (
                                    <></>
                                ) : (
                                    <Button rightIcon={<LuTestTube2/>} colorScheme='blue' variant='outline'
                                            marginTop={'50px'} onClick={addToVariants}>
                                        Add Variant
                                    </Button>
                                )}
                            </Stack>
                        ))}

                    </FormControl>
                            </AccordionPanel>
                        </AccordionItem>
                        </Accordion>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Close
                    </Button>
                    {incomingData === null ? (
                        <Button colorScheme="blue" mr={3} onClick={handleCreateExperiment}>Create Experiment</Button>
                    ) : (
                        <Button colorScheme="blue" mr={3} onClick={handleUpdateExperiment}>Update Experiment</Button>
                    )}
                </ModalFooter>
            </ModalContent>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Activation Event</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Event Name</FormLabel>
                            <Input
                                value={newEventName}
                                onChange={(e) => setNewEventName(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={() => setIsModalOpen(false)}>
                            Close
                        </Button>
                        <Button colorScheme="blue"  onClick={handleCreateEvent}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Modal>

    );
};

export default ABTestModal;