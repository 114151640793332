import {
    Box,
    Flex,
    Avatar,
    HStack,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useColorModeValue,
    Image,
    Stack,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import LogView from "./log_view";
import AddDeviceIdModal from "./add_device_id";
import {useEffect, useState} from "react";
import RemoteConfig from "./remote_config/remote_config_view";
import CodemagicBuilds from "./builds/builds_view";
import ABTestView from "./ab_test/ab_test_view";
import {getAuth, signOut } from "firebase/auth";
import {useDisclosure} from "@chakra-ui/hooks";

interface Props {
    children: React.ReactNode
}

const Links = ['Dashboard', 'Live Logger', 'Remote Config', "Builds", "AB Test"];

const NavLink = ({ children, setSelectedLink }) => {
    return (
        <Box
            as="a"
            px={2}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: useColorModeValue('gray.200', 'gray.700'),
            }}
            href={'#'}
            onClick={() => setSelectedLink(children)}>
            {children}
        </Box>
    )
}

export function NavBar({userToken, setUserToken, avatarUrl}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const addDeviceDisclosure = useDisclosure();
    const [selectedGameId, setSelectedGameId] = useState("com.ck.tripletiles");
    const [selectedLink, setSelectedLink] = useState("Live Logger");
    const [activeComponent, setActiveComponent] = useState("Live Logger");

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                // Sign-out successful.
                setUserToken(null);
                localStorage.removeItem('userToken');
            })
            .catch((error) => {
                // An error happened.
                console.error("Logout error:", error);
            });
    };

    useEffect(() =>  {

    }, [userToken]);

    return (
        <>
            <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'center'}>
                        <Box><Image src='static/alictus_avatar.png' width={'40px'}/> </Box>
                        <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                            {Links.map((link) => (
                                <NavLink setSelectedLink={(link) => { setSelectedLink(link); setActiveComponent(link); }} key={link}>{link}</NavLink>
                            ))}
                            {/*<GameSelectionDropdown userToken={userToken} setSelectedGameId={setSelectedGameId}/>*/}
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <Menu>
                            <MenuButton
                                as={Button}
                                rounded={'full'}
                                variant={'link'}
                                cursor={'pointer'}
                                minW={0}>
                                <Avatar
                                    size={'sm'}
                                    src={
                                        avatarUrl
                                    }
                                />
                            </MenuButton>
                            <MenuList>
                                {addDeviceDisclosure.isOpen && <AddDeviceIdModal userToken={userToken} isOpen={addDeviceDisclosure.isOpen} onClose={addDeviceDisclosure.onClose}/>}
                                <MenuItem onClick={()=>addDeviceDisclosure.onOpen()}>Add DeviceId</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <NavLink key={link}>{link}</NavLink>
                            ))}
                        </Stack>
                    </Box>
                ) : null}

            </Box>
            {activeComponent === 'Dashboard' &&  <LogView userToken={userToken} selectedGameId={selectedGameId}/>}
            {activeComponent === 'Live Logger' && <LogView userToken={userToken} selectedGameId={selectedGameId}/>}
            {activeComponent === 'Remote Config' &&  <RemoteConfig userToken={userToken} selectedGameId={selectedGameId}/>}
            {activeComponent === 'Builds' &&  <CodemagicBuilds userToken={userToken} />}
            {activeComponent === 'AB Test' &&  <ABTestView userToken={userToken} selectedGameId={selectedGameId}/>}

        </>
    )
}

export default NavBar;
