import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Grid, GridItem, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList,
} from '@chakra-ui/react';
import ABTestModal from "./add_ab_test_modal";
import config from "../config";
import {Icon, ViewIcon} from "@chakra-ui/icons";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import {formatTimestamp} from "../utility";

function AbTestByteToString(value){
    if(value === 0) return "Waiting to Start";
    if(value === 1) return "Running";
    if(value === 2) return "Completed";
    if(value === 3) return "No New Users";
    if(value === 4) return "Cancelled";
    if(value === 5) return "Paused for Today";
    if(value === 6) return "Target User Count Reached";
}

function ViewButton({onOpen}){
    return (
        <IconButton onClick={onOpen} aria-label='Add'
                    icon={<ViewIcon color={'gray.500'} _hover={{color: 'gray.300'}}/>}
                    backgroundColor={'transparent'} _hover={{bg: 'transparent', color: 'white'}}/>
    );
}

function ABTestData({userToken, selectedGameId}){
    const viewABTest = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedTest, setSelectedTest] = useState({}); // Placeholder for selected AB test details
    const [remoteAbTests, setRemoteAbTests] = useState(null);


    useEffect(() => {
        let url = config.WEBSOCKET_ENDPOINT + '/ab_test/' + selectedGameId + '/stream';
        let webSocket = new WebSocket(url);

        webSocket.onopen = (event) => {
            webSocket.send(userToken)
        };

        webSocket.onclose = (event) => {
            console.log('WebSocket is closed:', event);
        };

        webSocket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        webSocket.onmessage = (event) => {
            let ab_tests = JSON.parse(event.data);
            setRemoteAbTests(ab_tests);
        };

    }, [selectedGameId]);


    const openModal = (test) => {
        setSelectedTest(test);
        viewABTest.onOpen();
    };

    const updateAbState = (testId, state) => {
        let url = config.API_ENDPOINT + '/ab_test/' + selectedGameId + '/' + testId + '/state';
        axios.put(url, {"State": state}, { headers: { Authorization: "Bearer " + userToken } })
            .then((response) => {

            });
    };


    return (
        <Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Id</Th>
                        <Th>Platform</Th>
                        <Th>Activation Date</Th>
                        <Th>Total Users</Th>
                        <Th>Daily Users</Th>
                        <Th>End Date</Th>
                        <Th>State</Th>
                        <Th textAlign="center" align={'center'} width={'20px'} >View</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {remoteAbTests !== null && remoteAbTests.map((test) => (
                        <Tr key={test._id}>
                            <Td>{test.Name}</Td>
                            <Td>{test.Id}</Td>
                            <Td>{test.Platform}</Td>
                            <Td>{formatTimestamp(test.ActivationDate)}</Td>
                            <Td>{test.TotalUserCount}</Td>
                            <Td>{test.UserCountToday}</Td>
                            <Td>{formatTimestamp(test.EndDate)}</Td>
                            <Td>{AbTestByteToString(test.State)}</Td>
                            <Td textAlign="center" align={'center'} width={'20px'}><Button onClick={() => openModal(test)} leftIcon={<Icon as={ViewIcon} color={'gray.200'} _hover={{ color:'gray.300' }} />}   variant='simple'/></Td>
                            <Td>
                                {test.State !== 4 && test.State !== 2 ? (
                                <Menu>
                                    <MenuButton as={Button} rounded={'full'}
                                                variant={'link'}
                                                cursor={'pointer'}
                                                minW={0}>
                                        <IconButton onClick={onOpen} aria-label='Add'
                                                    icon={<PiDotsThreeOutlineVerticalFill color={'gray.500'} _hover={{color: 'gray.300'}}/>}
                                                    backgroundColor={'transparent'} _hover={{bg: 'transparent', color: 'white'}}/>
                                    </MenuButton>
                                    <MenuList>
                                        {test.State === 0 && (
                                        <MenuItem onClick={()=>updateAbState(test._id, 1)}>Start Test</MenuItem>
                                            )}
                                        {(test.State !== 0 && test.State !== 2 && test.State !== 4) && (
                                        <MenuItem onClick={()=>updateAbState(test._id, 4)}>Cancel Test</MenuItem>
                                            )}
                                        {test.State === 1 && (
                                            <MenuItem onClick={()=>updateAbState(test._id, 3)}>Stop Acquiring Users</MenuItem>
                                        )}
                                        {(test.State === 1 || test.State === 3 || test.State === 5 || test.State === 6) && (
                                        <MenuItem onClick={()=>updateAbState(test._id, 2)}>Mark as Complete</MenuItem>)}
                                    </MenuList>
                                </Menu>
                                ) : (
                                    test.State === 4 ? "Cancelled" : "Completed"
                                )}
                              </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <ABTestModal userToken={userToken} gameIdentity={selectedGameId} title={'Edit AB Test'} isOpen={viewABTest.isOpen} onClose={viewABTest.onClose} incomingData={selectedTest}/>
        </Box>
    );
}

function ABTestView({userToken, selectedGameId}) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Grid
            height={'1px'}
            width={'100%'}
            templateRows='repeat(4, 1fr)'
            templateColumns='repeat(5, 1fr)'
            gap={2}
            paddingLeft={'12px'}
            paddingRight={'10px'}
        >
            <GridItem colSpan={6} padding={'15px'}>
                {userToken && selectedGameId &&(
                <ABTestModal userToken={userToken} gameIdentity={selectedGameId} title={'Create AB Test'} isOpen={isOpen} onClose={onClose} />
                )}
                {selectedGameId !== null &&
                    <Flex justifyContent='flex-end'>
                            <Button colorScheme='blue' size='xs' onClick={onOpen} >
                                Create Config
                            </Button>
                    </Flex>
                }
            </GridItem>
            <GridItem paddingLeft={'20px'} colSpan={6}>
                {selectedGameId !== null &&
                <ABTestData userToken={userToken} selectedGameId={selectedGameId}/>}
            </GridItem>
        </Grid>
    );
}

export default ABTestView;