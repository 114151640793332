import {
    Button,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    IconButton,
    Grid,
    GridItem,
    HStack,
    VStack,
    Text,
    Flex, Spacer, Tag, Textarea, Select, FormControl
} from '@chakra-ui/react'
import pako from "pako";
import {CloseIcon, EditIcon,  Icon, PlusSquareIcon, ViewIcon} from "@chakra-ui/icons"
import {useDisclosure} from "@chakra-ui/hooks";
import AddRCVariableModal from './add_rc_variable_modal';
import React, { useState, useEffect } from 'react';
import {FaTrashAlt} from 'react-icons/fa';
import {BsFillCheckCircleFill} from 'react-icons/bs';
import {CgCloseO} from 'react-icons/cg';
import axios from 'axios';
import RemoteConfigPackageModal from "./add_rc_package";
import {FilterType, FilterCondition, Countries} from "./enums.js";
import DeleteRemoteConfigPackageModal from "./delete_rc_package_modal";
import EditRemoteConfigModal from "./edit_remote_config_package";
import EditRCVariableModal from "./edit_remote_variable_modal";
import DeleteRCKeyModal from "./delete_rc_key_modal";
import config from '../config';
import {formatTimestamp} from "../utility";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'

const countryMapping = Countries.map((country) => { return { value: country.code, label: country.name }; });


class ViewFilter
{
    constructor()
    {
        this.ViewOnlyActive = true;
        this.ViewVersion = null;
        this.ExclusiveVersion = false;
        this.ViewCountry = null;
        this.ExclusiveCountry = false;
        this.ViewPlatform = null;
        this.ExclusivePlatform = false;
    }

    toggleViewOnlyActive()
    {
        this.ViewOnlyActive = !this.ViewOnlyActive;
        return this;
    }

    checkIfFilterExists(rcValue, filterId)
    {
        for (let i = 0; i < rcValue.FilterOperations.length; i++) {
            let filterOp = rcValue.FilterOperations[i];
            if (filterOp.Filter === filterId)
                return true;
        }
        return false;
    }

    canDisplay(rcValue){
        if(this.ViewOnlyActive && !rcValue.IsActive)
            return false;
        if(!this.hasFilter()) return true;
        if(this.hasExclusiveFilter())
        {
            if(this.ViewVersion && this.ExclusiveVersion)
            {
                if(!this.checkIfFilterExists(rcValue,2)) return false;
            }

            if(this.ViewCountry && this.ExclusiveCountry)
            {
                if(!this.checkIfFilterExists(rcValue,1)) return false;
            }

            if(this.ViewPlatform && this.ExclusivePlatform)
            {
                if(!this.checkIfFilterExists(rcValue,4)) return false;
            }
        }

        for (let i = 0; i < rcValue.FilterOperations.length; i++) {
            let filterOp = rcValue.FilterOperations[i];
            console.log(filterOp);
            if (filterOp.Filter === 2 && this.ViewVersion && !filterOp.Values.includes(this.ViewVersion))
                return false;
            if (filterOp.Filter === 1 && this.ViewCountry && !filterOp.Values.map(value => value.toLowerCase()).includes(this.ViewCountry))
                return false;
            if (filterOp.Filter === 4 && this.ViewPlatform && !filterOp.Values.map(value => value.toLowerCase()).includes(this.ViewPlatform))
                return false;
        }
        return true;
    }

    hasFilter(){
        return this.ViewVersion || this.ViewCountry ||  this.ViewPlatform;

    }

    hasExclusiveFilter(){
        return this.ExclusiveVersion || this.ExclusiveCountry || this.ExclusivePlatform;
    }
}

function RemoteConfigData({userToken, gameIdentity})
{
    const [mainRemotePackage, setMainRemotePackage] = useState(null);
    const [privateRemotePackages, setPrivateRemotePackages] = useState([]);
    const webSocketRef = React.useRef(null);

    useEffect(() => {
        let webSocket;

        if (!webSocketRef.current) {
            let url = config.WEBSOCKET_ENDPOINT + '/rc/' + gameIdentity + '/stream';
            webSocket = new WebSocket(url);
            webSocketRef.current = webSocket;

            webSocket.onopen = (event) => {
                webSocket.send(userToken)
            };

            webSocket.onclose = (event) => {
                console.log('WebSocket is closed:', event);
            };

            webSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            webSocket.onmessage = async (event) => {
                let data;
                if (event.data instanceof Blob) {
                    const arrayBuffer = await event.data.arrayBuffer();
                    const uint8Array = new Uint8Array(arrayBuffer);
                    data = pako.inflate(uint8Array, { to: 'string' });
                } else {
                    data = event.data;
                }

                let packages = JSON.parse(data);
                if (packages.length > 0) {
                    let privatePackages = [];
                    for(let i = 0; i < packages.length; i++) {
                        if(packages[i].IsMainConfig) {
                            setMainRemotePackage(packages[i]);
                        } else {
                            privatePackages.push(packages[i]);
                        }
                    }
                    setPrivateRemotePackages(privatePackages);
                }
            };
        }

        return () => {
            if (webSocketRef.current) {
                webSocketRef.current.close();
                webSocketRef.current = null;
            }
        };
    }, [gameIdentity, userToken]);

    return <DisplayRemoteConfigDatas userToken={userToken} remoteConfigPackageData={mainRemotePackage} OtherPackages={privateRemotePackages}/>
}

function DisplayRemoteConfigDatas({userToken, remoteConfigPackageData, OtherPackages})
{
    return (remoteConfigPackageData && OtherPackages) && (
        <VStack  spacing={10}  align='stretch'>
            <DisplayRemoteConfigData userToken={userToken} remoteConfigPackageData={remoteConfigPackageData}/>
            {OtherPackages.map((packageData, i) => (
                <DisplayRemoteConfigData userToken={userToken} remoteConfigPackageData={packageData}/>
            ))}
        </VStack>
    );
}

function DisplayRemoteConfigData({userToken, remoteConfigPackageData})
{
    const addVariableDisclosure = useDisclosure();
    const editPackageDisclosure = useDisclosure();
    const deletePackageDisclosure = useDisclosure();
    const editRCVariableDisclosure = useDisclosure();
    const deleteRCKeyDisclosure = useDisclosure();
    const [rcVariableData, setRCVariableData] = useState(null);
    const [deleteRCKeyData, setDeleteRCKeyData] = useState(null);
    //const [showDisabledKeys, setShowDisabledKeys] = useState(false);
    const [viewFilter, setViewFilter] = useState(new ViewFilter());

    useEffect(() => {
        editRCVariableDisclosure.onOpen();

    },[rcVariableData])

    const multiFunction =(disc, rcData)=>
    {
        setRCVariableData(rcData);
        disc.onOpen();
    };

    return (
        <Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
            <AddRCVariableModal userToken={userToken} disclosure={addVariableDisclosure} packageData={remoteConfigPackageData}/>
            <DeleteRemoteConfigPackageModal userToken={userToken} packageId={remoteConfigPackageData._id} title={'Delete Remote Config Package'} disclosure={deletePackageDisclosure}/>
            <EditRemoteConfigModal userToken={userToken}  disclosure={editPackageDisclosure} packageData={remoteConfigPackageData}/>

            {deleteRCKeyDisclosure.isOpen && deleteRCKeyData && (
                <DeleteRCKeyModal
                    userToken={userToken}
                    disclosure={deleteRCKeyDisclosure}
                    packageId={remoteConfigPackageData._id}
                    rcKey={deleteRCKeyData.Key}
                    rcValueId={deleteRCKeyData._id}
                />
            )}

            {editRCVariableDisclosure.isOpen && rcVariableData && (
                <EditRCVariableModal
                    userToken={userToken}
                    disclosure={editRCVariableDisclosure}
                    variableData={rcVariableData}
                    rcConfigId={remoteConfigPackageData._id}
                />
            )}

            <VStack  spacing={1}  align='stretch'>
                <HStack width={'100%'}>
                <SectionTitle packageData={remoteConfigPackageData} addVariableDisclosure={addVariableDisclosure} editPackageDisclosure={editPackageDisclosure} viewFilter={viewFilter} setViewFilter={setViewFilter}/>
                    {remoteConfigPackageData.IsMainConfig === false && (
                    <Flex justifyContent='flex-end'  width={'100%'}>
                        <DeleteButton paddingTop={"2px"} onOpen={deletePackageDisclosure.onOpen}/>
                    </Flex>)}
                </HStack>
                <TableContainer>
                    <Table variant='simple' size='md' border={'0px'}>
                        <Thead>
                            <Tr>
                                <Th width="150px">Name</Th>
                                <Th width="200px">Description</Th>
                                <Th width="200px">Value</Th>
                                <Th width="150px">Type</Th>
                                <Th width="150px">Filter</Th>
                                <Th width="200px">Latest Update</Th>
                                <Th width="200px">Status</Th>
                                <Th width="50px">View</Th>
                                <Th width="50px">Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {remoteConfigPackageData && remoteConfigPackageData.Values.sort((a, b) => a.Key.localeCompare(b.Key)).map((field, i) => (
                                (viewFilter.canDisplay(field)) ? (
                                    <Tr key={i}>
                                        <Td width="150px">{field.Key}</Td>
                                        <Td width={'200px'} maxW={'200px'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" position="relative" _hover={{ overflow: "visible", whiteSpace: "normal" }}>
                                            {field.Description}
                                            <Box position="absolute" bg="gray.700" color="white" p="2" borderRadius="md" visibility="hidden" _groupHover={{ visibility: "visible" }} zIndex="tooltip">
                                                {field.Description}
                                            </Box>
                                        </Td>
                                        <Td width="200px">
                                            <Text maxW="200px" isTruncated>
                                                {String(field.Value)}
                                            </Text>
                                        </Td>
                                        <Td width="150px">{field.Type}</Td>
                                        <Td width="250px">
                                            {field.FilterOperations.length > 0 && (
                                                <Textarea maxW="250px" fontSize={'sm'} border={'0px'}>
                                                    {FilterToString(field.FilterOperations)}
                                                </Textarea>
                                            )}
                                        </Td>
                                        <Td width="200px">{formatTimestamp(field.LastUpdated)}</Td>
                                        <Td width="200px">
                                            {field.IsActive ? (
                                                <Button onClick={() => toggleRCValueStatus(userToken, remoteConfigPackageData._id, field._id)} leftIcon={<Icon as={BsFillCheckCircleFill} color={'green.200'} _hover={{ color: 'gray.300' }} />} variant='simple' />
                                            ) : (
                                                <Button onClick={() => toggleRCValueStatus(userToken, remoteConfigPackageData._id, field._id)} leftIcon={<Icon as={CgCloseO} color={'red.200'} _hover={{ color: 'gray.300' }} />} variant='simple' />
                                            )}
                                        </Td>
                                        <Td width="50px">
                                            <ViewButton onOpen={() => multiFunction(editRCVariableDisclosure, field)} />
                                        </Td>
                                        <Td width="50px">
                                            <Button
                                                onClick={() => {
                                                    setDeleteRCKeyData(field);
                                                    deleteRCKeyDisclosure.onOpen();
                                                }}
                                                leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
                                                variant='simple'
                                            />
                                        </Td>
{/*                                        <Td width="50px">
                                            <Button onClick={() => deleteRCValue(userToken, remoteConfigPackageData._id, field._id)} leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />} variant='simple' />
                                        </Td>*/}
                                    </Tr>
                                ) : null
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </Box>
    );
}

function toggleRCValueStatus(userToken, packageId, rcValueId)
{
    console.log("toggleRCValueStatus");
    axios.put(config.API_ENDPOINT + '/rc_package/' + packageId + '/' + rcValueId + '/toggle',
        {},
        {headers: {Authorization: "Bearer " + userToken}})
        .then((response) => {
            console.log(response);
        });
}

function FilterToString(filterOperations)
{
    let result = "";
    for(let i = 0; i<filterOperations.length; i++)
    {
        let filterOperation = filterOperations[i];
        const filterString = FilterType[filterOperation.Filter];
        const conditionString = FilterCondition[filterOperation.Condition];
        let valuesString = "";
        if(filterOperation.Values !== null && filterOperation.Values !== undefined && filterOperation.Values.length !== 0)
            valuesString = filterOperation.Values.join(", ");

        result += `Filter: ${filterString}, Condition: ${conditionString}, Values: [${valuesString}]`;
    }

    return result;
}


function PlusButton({paddingTop, onOpen})
{
    return(
        <IconButton onClick={onOpen} paddingTop={paddingTop}  aria-label='Add' icon={<PlusSquareIcon color={'gray.500'} _hover={{ color:'gray.300' }}/>} backgroundColor={'transparent'} _hover={{ bg: 'transparent', color:'white' }}/>
    );
}

function DeleteButton({paddingTop, onOpen})
{
    return(
        <IconButton onClick={onOpen} paddingTop={paddingTop}  aria-label='Delete Config' icon={<CloseIcon width={'10px'} color={'gray.500'} _hover={{ color:'gray.300' }}/>} backgroundColor={'transparent'} _hover={{ bg: 'transparent', color:'white' }}/>
    );
}

function EditButton({onOpen}) {
    return (
        <IconButton onClick={onOpen}  aria-label='History' icon={<EditIcon color={'gray.500'} _hover={{color: 'gray.300'}}/>}
                    backgroundColor={'transparent'} _hover={{bg: 'transparent', color: 'white'}}/>
    );
}

function ViewButton({onOpen}){
    return (
        <IconButton onClick={onOpen} aria-label='Add'
                    icon={<ViewIcon color={'gray.500'} _hover={{color: 'gray.300'}}/>}
                    backgroundColor={'transparent'} _hover={{bg: 'transparent', color: 'white'}}/>
    );
}

function sortVersions(versions) {
    return versions.sort((a, b) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aPart = aParts[i] || 0;
            const bPart = bParts[i] || 0;

            if (aPart < bPart) return -1;
            if (aPart > bPart) return 1;
        }

        return 0;
    });
}

function SectionTitle({ packageData, addVariableDisclosure, editPackageDisclosure, viewFilter, setViewFilter}){
    const[filteredCountry, setFilteredCountry] = useState("");
    const[filteredVersion, setFilteredVersion] = useState("");
    const[filteredPlatform, setFilteredPlatform] = useState("");
    const[availableVersions, setAvailableVersions] = useState([]);
    const[availableCountries, setAvailableCountries] = useState([]);

    const getUniqueVersions = (rcPackage) => {
        let uniqueVersions = [];
        let newVersionFound = false;

        rcPackage.Values.forEach((value) => {
            for(let i = 0; i<value.FilterOperations.length; i++)
            {
                let filterOp = value.FilterOperations[i];
                if(filterOp.Filter === 2) // FilterType.Version
                {
                    if(filterOp.Values !== null && filterOp.Values !== undefined)
                    {
                        for(let i = 0; i< filterOp.Values.length; i++)
                        {
                            if(!uniqueVersions.includes(filterOp.Values[i]))
                            {
                                uniqueVersions.push(filterOp.Values[i]);
                                newVersionFound = true;
                            }
                        }
                    }
                }
            }
        });

        if(newVersionFound || uniqueVersions.length !== availableVersions.length)
        {
            let sortedUnique = sortVersions(uniqueVersions);
            sortedUnique.unshift("Select Version");
            setAvailableVersions(sortedUnique);
        }
    }

    const getUniqueCountries = (rcPackage) => {
        let uniqueCountries = [];
        let newCountryFound = false;

        rcPackage.Values.forEach((value) => {
            for(let i = 0; i<value.FilterOperations.length; i++)
            {
                let filterOp = value.FilterOperations[i];
                if(filterOp.Filter === 1) // FilterType.Country
                {
                    if(filterOp.Values !== null && filterOp.Values !== undefined)
                    {
                        for(let i = 0; i< filterOp.Values.length; i++)
                        {
                            if(!uniqueCountries.includes(filterOp.Values[i]))
                            {
                                uniqueCountries.push(filterOp.Values[i]);
                                newCountryFound = true;
                            }
                        }
                    }
                }
            }
        });

        if(newCountryFound || uniqueCountries.length !== availableCountries.length)
        {
            let sortedUnique = uniqueCountries.sort();
            sortedUnique.unshift("Select Country");
            setAvailableCountries(sortedUnique);
        }
    }

    useEffect(() => {
        getUniqueVersions(packageData);
        getUniqueCountries(packageData);
    }, [packageData]);

/*    useEffect(() => {
        console.log(viewFilter);
    }, [viewFilter]);*/

    useEffect(() => {
        let value = null;
        if(filteredVersion !== "Select Version") {
            value = filteredVersion;
        }

        setViewFilter((prevViewFilter) => {
            const updatedFilter = new ViewFilter();
            Object.assign(updatedFilter, prevViewFilter);
            updatedFilter.ViewVersion = value;
            //console.log(updatedFilter);
            return updatedFilter;
        });
    }, [filteredVersion, setViewFilter]);

    useEffect(() => {
        let value = null;
        if(filteredPlatform !== "Select Platform") {
            value = filteredPlatform.toLowerCase();
        }

        setViewFilter((prevViewFilter) => {
            const updatedFilter = new ViewFilter();
            Object.assign(updatedFilter, prevViewFilter);
            updatedFilter.ViewPlatform = value;
            //console.log(updatedFilter);
            return updatedFilter;
        });
    }, [filteredPlatform, setViewFilter]);

    useEffect(() => {
        let value = null;
        if(filteredCountry !== "Select Country") {
            value = filteredCountry.toLowerCase();
        }

        setViewFilter((prevViewFilter) => {
            const updatedFilter = new ViewFilter();
            Object.assign(updatedFilter, prevViewFilter);
            updatedFilter.ViewCountry = value;
            //console.log(updatedFilter);
            return updatedFilter;
        });
    }, [filteredCountry, setViewFilter]);

    return (
        <HStack width='100%' >
            <Box flex={1} h='60px' bg='gray.800' paddingLeft={'10px'} display="flex">
                <HStack spacing='-5px' padding={'5px'} >
                    <strong style={{ width: '100px', display: 'inline-block' }}>{packageData.Name}</strong>

                        {packageData.IsMainConfig !== true && (
                            <EditButton onOpen={editPackageDisclosure.onOpen}/> )}
                        {packageData.IsMainConfig !== true && (<Spacer />)}
                        {( <HStack flex={1}  >
                            <PlusButton onOpen={addVariableDisclosure.onOpen} paddingTop={"2px"}/>
                                <HStack  flex={1}  margin={0}>

                                <Checkbox margin={0} flex="1" minW={'200px'} onChange={() => setViewFilter((prevViewFilter) => {
                                    const updatedFilter = new ViewFilter();
                                    Object.assign(updatedFilter, prevViewFilter);
                                    updatedFilter.ViewOnlyActive= !viewFilter.ViewOnlyActive
                                    return updatedFilter;
                                })  }>View Disabled Keys</Checkbox>

                                    <FormControl flex="1" minW={'200px'}>
                                        <Select
                                            value={filteredPlatform}
                                            onChange={(e) => setFilteredPlatform(e.target.value)}
                                        >
                                            {["Select Platform", "iOS", "Android"].map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Checkbox paddingRight={'15px'}  onChange={() => setViewFilter((prevViewFilter) => {
                                        const updatedFilter = new ViewFilter();
                                        Object.assign(updatedFilter, prevViewFilter);
                                        updatedFilter.ExclusivePlatform= !viewFilter.ExclusivePlatform
                                        return updatedFilter;
                                    })  }>Excl.</Checkbox>

                                <FormControl flex="1" minW={'200px'}>
                                    <Select
                                        value={filteredVersion}
                                        onChange={(e) => setFilteredVersion(e.target.value)}
                                    >
                                        {availableVersions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                                    <Checkbox paddingRight={'15px'}  onChange={() => setViewFilter((prevViewFilter) => {
                                        const updatedFilter = new ViewFilter();
                                        Object.assign(updatedFilter, prevViewFilter);
                                        updatedFilter.ExclusiveVersion= !viewFilter.ExclusiveVersion
                                        return updatedFilter;
                                    })  }>Excl.</Checkbox>

                                    <FormControl flex="1" minW={'200px'}>
                                        <Select
                                            value={filteredCountry}
                                            onChange={(e) => setFilteredCountry(e.target.value)}
                                        >
                                            {availableCountries.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Checkbox paddingRight={'15px'} width={'300px'}  onChange={() => setViewFilter((prevViewFilter) => {
                                        const updatedFilter = new ViewFilter();
                                        Object.assign(updatedFilter, prevViewFilter);
                                        updatedFilter.ExclusiveCountry= !viewFilter.ExclusiveCountry
                                        return updatedFilter;
                                    })  }>Excl.</Checkbox>
                                </HStack>
                        </HStack>
                        )}

                    {packageData.IsMainConfig !== true && (
                    <HStack spacing={4}>
                        <Tag size={'md'} variant='outline' colorScheme='blue'>
                            Active
                        </Tag>
                        {packageData.AssignedDeviceIds.map((deviceId, i) => (
                            <Tag size={'md'} variant='outline' colorScheme='green'>
                                {deviceId}
                            </Tag>
                        ))}
                    </HStack>)}
                </HStack>
            </Box>
        </HStack>

    );
}

function RemoteConfig({userToken, selectedGameId}) {
    const createRemoteConfigPackage = useDisclosure();

    return (
        <Grid
            height={'1px'}
            width={'100%'}
            templateRows='repeat(4, 1fr)'
            templateColumns='repeat(5, 1fr)'
            gap={2}
            paddingLeft={'12px'}
            paddingRight={'10px'}
        >
            <GridItem colSpan={6} padding={'15px'}>
                <RemoteConfigPackageModal userToken={userToken} identifier={selectedGameId} title={'Add Remote Config Package'} isOpen={createRemoteConfigPackage.isOpen} onClose={createRemoteConfigPackage.onClose} />
                <Flex justifyContent='flex-end'>
                    <Button colorScheme='blue' size='xs' onClick={createRemoteConfigPackage.onOpen} >
                        Create Config
                    </Button>
                </Flex>
            </GridItem>
            <GridItem paddingLeft={'20px'} colSpan={6}>
                <RemoteConfigData userToken={userToken} gameIdentity={selectedGameId}/>
            </GridItem>
        </Grid>
    );
}

export default RemoteConfig;
